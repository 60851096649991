<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/registration/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')">

			<app-input-text label="Name" :maxlength="64" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-checklist :locked="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Type" v-model="model.type" :validation="$v.model.type" :options="$constants.registration.item.typeName" :descriptions="$constants.registration.item.typeDescription" />
			<app-input-suggest :defaultValue="''" v-if="model.type === $constants.registration.item.type.badge" label="Badge Type" placeholder="Search badge types..." v-model="model.badge" :validation="$v.model.badge" api="convention/badge/type" />
			<app-input-suggest :defaultValue="''" v-if="model.type === $constants.registration.item.type.ticket" label="Ticket Type" placeholder="Search ticket types..." v-model="model.ticket" :validation="$v.model.ticket" api="convention/schedule/ticket" v-on:selected="onTicketSelected" />
			<app-input-toggle v-if="model.type === $constants.registration.item.type.badge" label="Upgrade" v-model="model.upgrade" :validation="$v.model.upgrade" />
			<app-input-tags v-if="model.type === $constants.registration.item.type.merchandise" notes="Add one or more tags to help find merchandise when building store categories or registration pages." label="Tags" placeholder="Enter description..." v-model="model.tags" :validation="$v.model.tags" />
			<app-input-text label="Description" :autogrow="true" placeholder="Enter description..." v-model="model.description" :validation="$v.model.description" />
			<app-input-image v-if="model.type !== $constants.registration.item.type.other" label="Image" placeholder="Enter image..." v-model="model.image" :validation="$v.model.image" />

		</app-content-box>

		<app-content-box :readonly="$authorised('con/registration/access', 'read')">

			<app-input-checklist :locked="!ticketCapacity" :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Status" v-model="model.status" :validation="$v.model.status" :options="$constants.registration.item.statusName" :descriptions="$constants.registration.item.statusDescription">

				<app-content-note v-if="!ticketCapacity" theme="red" position="below">You cannot turn this item Online as the selected ticket type has no capacity left.</app-content-note>

			</app-input-checklist>
			
			<template v-if="isTicketType">
				<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Events" v-model="model.event.enabled" :validation="$v.model.event.enabled" :options="$constants.registration.item.eventName" :descriptions="$constants.registration.item.eventDescription" />
				<app-input-suggest :defaultValue="''" :disabled="!model.ticket" v-if="model.event.enabled === $constants.registration.item.event.specific" label="Specific Event" notes="Search and select the specific event that the attendee will be seated on after purchasing this ticket." placeholder="Search ticketed events..." v-model="model.event.specific" :validation="$v.model.event.specific" api="convention/schedule/event" :params="{ticket: model.ticket}" />
			</template> 

			<app-input-checklist :collapsed="!isNew" :fullWidth="true" :singular="true" :columns="1" label="Eligibility" v-model="model.applicable.enabled" :validation="$v.model.applicable.enabled" :options="$constants.registration.item.restrictName" :descriptions="$constants.registration.item.restrictDescription" />
			<app-input-toggle v-if="model.applicable.enabled" label="Eligible Badges" :column="true" :asString="true" :multiple="true" v-model="model.applicable.badges" :validation="$v.model.applicable.badges" :options="badgeOptions" />
			<app-input-checklist :collapsed="!isNew" v-if="canVariant" :fullWidth="true" :singular="true" :columns="1" label="Pricing" v-model="model.variants.enabled" :validation="$v.model.variants.enabled" :options="$constants.registration.item.variantsName" :descriptions="$constants.registration.item.variantsDescription" />
			<app-input-checklist :collapsed="!isNew" v-if="model.variants.enabled && model.type === $constants.registration.item.type.merchandise" :fullWidth="true" :singular="true" :columns="1" label="Wildcard Comps" v-model="model.wildcard.enabled" :validation="$v.model.wildcard.enabled" :options="$constants.registration.item.wildcardName" :descriptions="$constants.registration.item.wildcardDescription" />
			<app-input-date :time="true" label="Available from" placeholder="Enter date..." :toggle="model.from.enabled" v-on:toggle="model.from.enabled = !model.from.enabled" v-model="model.from.date" :validation="$v.model.from.date" />
			<app-input-date :time="true" label="Available until" placeholder="Enter date..." :toggle="model.to.enabled" v-on:toggle="model.to.enabled = !model.to.enabled" v-model="model.to.date" :validation="$v.model.to.date" />
			
			<app-input-toggle label="Refunds allowed" v-model="model.refunds.enabled" :validation="$v.model.refunds.enabled" />
			<app-input-date v-if="model.refunds.enabled" label="Refunds until" placeholder="Enter date..." :toggle="model.refunds.to.enabled" v-on:toggle="model.refunds.to.enabled = !model.refunds.to.enabled" v-model="model.refunds.to.date" :validation="$v.model.refunds.to.date" />
			
		</app-content-box>

		<app-content-box v-if="!hasVariants" :readonly="$authorised('con/registration/access', 'read')">

			<app-input-text label="Price" prefix="$" placeholder="Enter amount..." v-model="model.price" :validation="$v.model.price" />
			<app-input-text v-if="isTicketType" label="Ticket Quantity" placeholder="Enter amount..." notes="Set the number of tickets that the purchase gives (e.g. Buy 1 Get 1 Free)" :toggle="model.tickets.enabled" v-on:toggle="model.tickets.enabled = !model.tickets.enabled" v-model="model.tickets.amount" :validation="$v.model.tickets.amount" />
			<app-input-text label="Stock Limit" placeholder="Enter amount..." :toggle="model.quantity.enabled" notes="Set a maximum number that can be sold of this item across all purchases." v-on:toggle="model.quantity.enabled = !model.quantity.enabled" v-model="model.quantity.amount" :validation="$v.model.quantity.amount" />
			
		</app-content-box>

		<app-content-section v-if="!isNew && hasVariants" route="Convention.Registration.Item.Variants" :params="{ item: model.id }" title="Variants" description="Manage the variants of this item." :count="item.count.variants" />
		<app-content-section v-if="!isNew" route="Convention.Registration.Item.Comps" :params="{ item: model.id }" title="Comps" description="Manage any additional items that are comped when this item is purchased." :count="item.count.comps" />

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, between } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			ticketCapacity: true,
			readonly: this.$authorised('con/registration/access', 'read'),
			references: {
				tickets: [],
				badges: [],
			},
			model: {
				id: false,
				name: '',
				type: 0,
				badge: '',
				ticket: '',
				upgrade: 0,
				status: 1,
				description: '',
				image: '',
				tags: [],
				applicable: {
					enabled: 0,
					badges: []
				},
				price: 0,
				variants: {
					enabled: 0
				},
				event: {
					enabled: 0,
					specific: ''
				},
				wildcard: {
					enabled: false
				},
				quantity: {
					enabled: false,
					amount: 0
				},
				tickets: {
					enabled: false,
					amount: 1
				},
				from: {
					enabled: false,
					date: 0
				},
				to: {
					enabled: false,
					date: 0
				},
				refunds: {
					enabled: 0,
					to: {
						enabled: false,
						date: 0
					}
				}
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			type: {
				required,
				between: between(1,4)
			},
			badge: {
				minLength: function(value) {

					return !this.isBadgeType || value.length > 3

				}
			},
			ticket: {
				minLength: function(value) {

					return !this.isTicketType || value.length > 3

				}
			},
			applicable: {},
			event: {
				specific: {
					minLength: function(value) {

						return !this.model.event.enabled || value.length > 3

					}
				},
			},
			tickets: {},
			variants: {},
			wildcard: {},
			quantity: {},
			from: {},
			to: {},
			refunds: {
				to: {}
			}
		}
	},

	methods: {

		onTicketSelected: function(ticket) {

			this.ticketCapacity = ticket.remaining

		}

	},

	computed: {

		canVariant: function() {

			return this.model.type === this.$constants.registration.item.type.merchandise || this.model.type === this.$constants.registration.item.type.ticket

		},

		isBadgeType: function() {

			return this.model.type === this.$constants.registration.item.type.badge

		},

		isTicketType: function() {

			return this.model.type === this.$constants.registration.item.type.ticket

		},

		hasVariants: function() {

			return this.model.variants.enabled && this.canVariant

		},

		badgeOptions: function() {

			var options = []

			this.$_.each(this.references.badges, function(badge) {
				
				options.push({
					value: badge.id,
					text: badge.name
				})

			})

			return options

		},

		ticketOptions: function() {

			var options = []

			this.$_.each(this.references.tickets, function(ticket) {
				
				options.push({
					value: ticket.id,
					text: ticket.name
				})

			})

			return options

		}

	}

}

</script>

<style scoped>

</style>